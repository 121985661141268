import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";

export const connectionWeb3 = () => {
    const connection = new Connection("https://mainnet.helius-rpc.com/?api-key=d73510b6-389e-4fee-9d4b-bdb4737dfbe2", "confirmed");

    function getPublicKey(publicAddress) {
        return new PublicKey(publicAddress);
    }

    async function getTokenBalance(walletAddress, tokenAddress) {
        const accounts = await connection.getParsedTokenAccountsByOwner(getPublicKey(walletAddress),
            {
                mint: getPublicKey(tokenAddress)
            })
        const balance = accounts.value[0] ? parseFloat(accounts.value[0].account.data.parsed.info.tokenAmount.uiAmount) : 0;
        return balance;
    }

    async function getBalance(walletAddress) {
        let balance = await connection.getBalance(getPublicKey(walletAddress))
        balance = balance / 10 ** 9
        return balance;
    }

    async function accountInfo(tokenAddress) {
        let accountInfo = ""
        try {
            accountInfo = await connection.getAccountInfo(getPublicKey(tokenAddress));
        } catch (error) {

        }
        return accountInfo;
    }


    return { connection,getPublicKey, getTokenBalance, getBalance, accountInfo }

} 