import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { botApis } from "../services/botApis";
import { exceptionHandling } from "../Common/CommonComponents";
import { Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

const SellLogs = () => {
    const { tokenAddress } = useParams();
    const [sellLogs, setSellLogs] = useState();
    const [copied, setCopied] = useState({});
    const sellLogsRef = useRef(null);
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getSellLogs(nextPage, "TOP");
    }, [])


    async function getSellLogs(page, callType) {
        try {
            const response = await botApis.getSellLogs(tokenAddress, page);
            if (response.status === 200) {

                const responseData = response.data.data;

                if (callType == "BOTTOM") {
                    const sellLogsTemp = [...sellLogs?.logs];
                    sellLogsTemp.push(...responseData?.logs);
                    responseData.logs = sellLogsTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setSellLogs(responseData);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    const maskAddress = (address, characters = 5) => {
        const startChars = address && address?.substring(0, characters); // Get the first 6 characters
        const endChars = address && address?.substring(address?.length - characters); // Get the last 6 characters
        return `${startChars}.....${endChars}`; // Combine with dots in between
    };

    const copyTokenAddress = (token, index) => {
        navigator.clipboard.writeText(token)
            .then(() => {
                setCopied({ ...copied, [index]: true });
                setTimeout(() => setCopied({ ...copied, [index]: false }), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Failed to copy:', error));
    };




    const onScrollSellLogs = async () => {
        if (sellLogsRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = sellLogsRef.current;
            if ((scrollTop + clientHeight).toFixed() === (scrollHeight).toFixed()) {
                let page = nextPage + 1, totalPages = Number(sellLogs.totalPages);
                if (page < totalPages) {
                    await getSellLogs(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    function checkStage(log) {
        let state = '-';
        if (sellLogs?.tokenDetails?.sellStage1 && sellLogs?.tokenDetails?.sellStage1[0] && sellLogs?.tokenDetails?.sellStage1[0]._id == log?._id) {
            state = `Stage 1`
        } else if (sellLogs?.tokenDetails?.sellStage2 && sellLogs?.tokenDetails?.sellStage2[0] && sellLogs?.tokenDetails?.sellStage2[0]._id == log?._id) {
            state = `Stage 2`
        } else if (sellLogs?.tokenDetails?.sellStage3 && sellLogs?.tokenDetails?.sellStage3[0] && sellLogs?.tokenDetails?.sellStage3[0]._id == log?._id) {
            state = `Stage 3`
        }
        return state;
    }

    return (<>
        <section className="top-area">
            <Container fluid>
                <Row>

                    <Col md={12}>
                        <div className="top-boxes">
                            <div className="selling-top-right mb-0">
                                <h5>Token Details</h5>
                            </div>
                            <div className="selling-option-top mt-1">
                                <h6>Address: {maskAddress(sellLogs?.tokenDetails?.address)} <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip`}>{copied[`tokenAddress`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                >
                                    <i class="far fa-copy" onClick={() => copyTokenAddress(sellLogs?.tokenDetails?.address, `tokenAddress`)}
                                    ></i>
                                </OverlayTrigger></h6>
                                <h6>Decimals: {sellLogs?.tokenDetails?.decimals}</h6>

                                <h6>Signature: {maskAddress(sellLogs?.tokenDetails?.swap_transaction?.signature)} <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id={`tooltip`}>{copied[`signature`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                >
                                    <i class="far fa-copy" onClick={() => copyTokenAddress(sellLogs?.tokenDetails?.swap_transaction?.signature, `signature`)}
                                    ></i>
                                </OverlayTrigger></h6>
                                <h6>Sol Amount: {sellLogs?.tokenDetails?.swap_transaction?.given_token}</h6>
                                <h6>Token Amount: {sellLogs?.tokenDetails?.balance}</h6>
                                <h6>Initial Price: {sellLogs?.tokenDetails?.initial_price}</h6>

                            </div>
                            <div className="selling-option-top mt-1">
                                <h6>Selling Stage 1</h6>
                                {sellLogs?.tokenDetails?.sellStage1.length > 0 ? sellLogs?.tokenDetails?.sellStage1?.map(item => <>
                                    <h6>Token Amount: {item.sell_amount}</h6>
                                    <h6>Selling Price: {item.current_price}</h6>
                                    <h6>Initial Price: {item.initial_price}</h6>
                                    <h6>Profit Percentage: {item.price_percentage}</h6>
                                    <h6>Selling Time: {new Date(item.createdAt).toLocaleString()}</h6>
                                </>) : <h6>No Sell</h6>}
                            </div>

                            <div className="selling-option-top mt-1">
                                <h6>Selling Stage 2</h6>
                                {sellLogs?.tokenDetails?.sellStage2.length > 0 ? sellLogs?.tokenDetails?.sellStage2?.map(item => <>
                                    <h6>Token Amount: {item.sell_amount}</h6>
                                    <h6>Selling Price: {item.current_price}</h6>
                                    <h6>Initial Price: {item.initial_price}</h6>
                                    <h6>Profit Percentage: {item.price_percentage}</h6>
                                    <h6>Selling Time: {new Date(item.createdAt).toLocaleString()}</h6>
                                </>) : <h6>No Sell</h6>}
                            </div>

                            <div className="selling-option-top mt-1">
                                <h6>Selling Stage 2</h6>
                                {sellLogs?.tokenDetails?.sellStage3.length > 0 ? sellLogs?.tokenDetails?.sellStage3?.map(item => <>
                                    <h6>Token Amount: {item.sell_amount}</h6>
                                    <h6>Selling Price: {item.current_price}</h6>
                                    <h6>Initial Price: {item.initial_price}</h6>
                                    <h6>Profit Percentage: {item.price_percentage}</h6>
                                    <h6>Selling Time: {new Date(item.createdAt).toLocaleString()}</h6>
                                </>) : <h6>No Sell</h6>}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>

                    <Col md={12}>
                        <div className="transaction-logs telegram-inner" ref={sellLogsRef} onScroll={onScrollSellLogs}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Current Price</th>
                                        <th>Price Percentage</th>
                                        <th>Sell Stage</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sellLogs?.logs?.map((log, index) => {
                                            return (
                                                <>
                                                    <tr>

                                                        <td>{log.current_price}</td>
                                                        <td>{log.price_percentage}</td>
                                                        <td>{checkStage(log)}</td>
                                                        <td>{new Date(log.createdAt).toLocaleString()}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>)

}
export default SellLogs;