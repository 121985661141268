import axios from "axios";
import { config } from "../config/config";
import { getToken, jsonHeaders } from "../helpers/auth-header";

const configJsonHeaders = () => {
    let jsonHeader = jsonHeaders();
    jsonHeader.Authorization = getToken();
    return {
        headers: jsonHeader
    }
}

// const configMultipartHeaders = () => {
//     let formDataHeaders = formDataHeader();
//     formDataHeaders.Authorization = getToken();
//     return {
//         headers: formDataHeaders
//     }
// }
export const botApis = {
    login,
    getAccountDetails,
    getNewTokenAddressLog,
    updateUser,
    getTransactionLogs,
    getRPCUrlLogs,
    removeRPCLogs,
    addRpcLogs,
    stopNewlyToken,
    startNewlyToken,
    
    getAdminData,
    lossStatus,
    getLatestTokens,
    getLatestTransactions,
    swapToken,
    getSellLogs,
    getBoughtTokens,
    // -------------Telegram----------
    checkTelegramConnection,
    startTelegramConnection,
    stopTelegramConnection,
    // -------------Pool----------------
    checkPool,
    startPool,
    stopPool,
    startInProcess,
    // -------------Buy----------------
    checkBuy,
    startBuy,
    stopBuy,
    // -------------Buy----------------
    checkSell,
    startSell,
    stopSell,
    // -------------Manual Token----------------
    checkManualPool,
    startManualPool,
    stopManualPool
}

function login(params) {
    return axios.post(`${config.apiUrl}/user/login`,params);
}
/* get wallet address detail */
function getAccountDetails(){
    return axios.get(`${config.apiUrl}/user/`,configJsonHeaders());
}

/* update */
function updateUser(params) {
    return axios.put(`${config.apiUrl}/user/update`,params,configJsonHeaders());
}

/*  */

function getNewTokenAddressLog(page) {
    return axios.get(`${config.apiUrl}/token/list?page=${page}`,configJsonHeaders());
}

/* get transaction log */

function getTransactionLogs(page){
    return axios.get(`${config.apiUrl}/log/transactions?page=${page}`,configJsonHeaders());  
}

/* getRpc url */

function getRPCUrlLogs(page) {
    return axios.get(`${config.apiUrl}/user/rpcs?page=${page}`,configJsonHeaders());
}

/* remove rpc */
function removeRPCLogs(id) {
    return axios.delete(`${config.apiUrl}/user/rpc/${id}`,configJsonHeaders());
}

/* Add rpc logs */

function addRpcLogs(params) {
    return axios.post(`${config.apiUrl}/user/rpc`,params,configJsonHeaders());
}

/* stop newly */

function stopNewlyToken() {
    return axios.delete(`${config.apiUrl}/token/stop/newly_token`,configJsonHeaders());
}

/* start newly */

function startNewlyToken(params) {
    return axios.post(`${config.apiUrl}/token/start/newly_token`,params,configJsonHeaders());
}

















function getAdminData() {
    return axios.get(`${config.apiUrl}/admin/`,configJsonHeaders());
}


function lossStatus(params) {
    return axios.put(`${config.apiUrl}/admin/loss-status`,params,configJsonHeaders());
}

function getLatestTokens(page) {
    return axios.get(`${config.apiUrl}/token?page=${page}`,configJsonHeaders());
}



function getLatestTransactions(page) {
    return axios.get(`${config.apiUrl}/token/transactions?page=${page}`,configJsonHeaders());
}

function getBoughtTokens(page,) {
    return axios.get(`${config.apiUrl}/token?page=${page}`,configJsonHeaders());
}

function swapToken(params) {
    return axios.post(`${config.apiUrl}/token/swap`,params,configJsonHeaders());
}

function getSellLogs(tokenAddress,page) {
    return axios.get(`${config.apiUrl}/token/sell_logs/${tokenAddress}?page=${page}`,configJsonHeaders());
}

// -------------------------------------------Telegram----------------------------------------------------------
function checkTelegramConnection() {
    return axios.get(`${config.apiUrl}/telegram/check_connection`,configJsonHeaders());
}

function startTelegramConnection() {
    return axios.post(`${config.apiUrl}/telegram/start_connection`,{},configJsonHeaders());
}

function stopTelegramConnection() {
    return axios.delete(`${config.apiUrl}/telegram/stop_connection`,configJsonHeaders());
}
// -------------------------------------------Pool----------------------------------------------------------
function checkPool() {
    return axios.get(`${config.apiUrl}/token/check_pool`,configJsonHeaders());
}

function startPool() {
    return axios.post(`${config.apiUrl}/token/start_pool`,{},configJsonHeaders());
}

function stopPool() {
    return axios.delete(`${config.apiUrl}/token/stop_pool`,configJsonHeaders());
}


function startInProcess() {
    return axios.post(`${config.apiUrl}/token/in_process`,{},configJsonHeaders());
}

// ------------------------------------------------Buy-------------------------------------------------------
function checkBuy() {
    return axios.get(`${config.apiUrl}/token/check_buy`,configJsonHeaders());
}

function startBuy() {
    return axios.post(`${config.apiUrl}/token/start_buy`,{},configJsonHeaders());
}

function stopBuy() {
    return axios.delete(`${config.apiUrl}/token/stop_buy`,configJsonHeaders());
}
// stopNewlyToken

// ------------------------------------------------Sell-------------------------------------------------------
function checkSell() {
    return axios.get(`${config.apiUrl}/token/check_sell`,configJsonHeaders());
}

function startSell() {
    return axios.post(`${config.apiUrl}/token/start_sell`,{},configJsonHeaders());
}

function stopSell() {
    return axios.delete(`${config.apiUrl}/token/stop_sell`,configJsonHeaders());
}



// ------------------------------------------------Manual Token Pool-------------------------------------------------------
function checkManualPool() {
    return axios.get(`${config.apiUrl}/token/check_manual_pool`,configJsonHeaders());
}

function startManualPool() {
    return axios.post(`${config.apiUrl}/token/start_manual_pool`,{},configJsonHeaders());
}

function stopManualPool() {
    return axios.delete(`${config.apiUrl}/token/stop_manual_pool`,configJsonHeaders());
}