import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../component/Home";
import Login from "../component/Login";
import SellLogs from "../component/SellLogs";

const Routing = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Login/>} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/sell_logs/:tokenAddress" element={<SellLogs />} />
                </Routes>
            </Router>
        </>
    );
};
export default Routing;