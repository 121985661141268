
import { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Row, Table, Tooltip, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling, formatBalance } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";

const TransactionLog = ({ maskAddress, copyTokenAddress, copied, convertUtcToLocal }) => {
    const [transactionList, setTransactionList] = useState([])
    const [transactionStatus, setTransactionStatus] = useState(true)
    const transactionlogRef = useRef(null);
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getTransaction(nextPage, "TOP")
    }, [])

    const getTransaction = async (page, callType) => {
        try {
            const response = await botApis.getTransactionLogs(page);
            if (response.status === 200) {
                let responseData = response.data.data;

                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...transactionList?.transactions];
                    latestTokensTemp.push(...responseData?.transactions);
                    responseData.transactions = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setTransactionList(responseData)
                setTransactionStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    const onScrollTransactionLogs = async () => {
        if (transactionlogRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = transactionlogRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(transactionList.totalPages);
                if (page < totalPages) {
                    await getTransaction(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    const tokenLogsObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsCallObserverTop(false);
            isCallObserverTopRef.current = false;
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        tokenLogsObserverTop.current = new IntersectionObserver(handleTokenLogsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (transactionList?.transactions?.length > 0) {
            tokenLogsObserverTop.current.observe(document.querySelector('.latest-tokens-logs-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, transactionList, transactionList?.transactions]);

    const handleTokenLogsObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallObserverTopRef.current) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getTransaction(0, "TOP");
        }
    };

    return (<>
        <Col sm={12} md={12} lg={9} className="mb-2">
            <div className="token-logs top-boxes">

                <div className="token-logs-top">
                    <h6>Transaction log</h6>
                </div>

                <div className="transaction-logs transaction-body token-mis-area transaction-section-bottom "
                    ref={transactionlogRef} onScroll={onScrollTransactionLogs}
                >
                    <Table >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>signature</th>
                                <th>Amount </th>
                                <th>Token Holding</th>
                                <th>Avg. Buying Price</th>
                                <th>Action</th>
                                <th>Action Start</th>
                                <th>Action End</th>
                            </tr>
                        </thead>
                        <tbody>
                            <div className="latest-tokens-logs-top"></div>
                            {
                                // (transactionList.transactions?.length <= 0)
                                transactionStatus == true
                                    ? (<tr>
                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                        <td ><Skeleton height={15} width={90} count={20} /></td>
                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                        <td ><Skeleton height={15} width={60} count={20} /></td>
                                        <td ><Skeleton height={15} width={60} count={20} /></td>
                                        <td ><Skeleton height={15} width={60} count={20} /></td>
                                        <td ><Skeleton height={15} width={60} count={20} /></td>
                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                        <td ><Skeleton height={15} width={80} count={20} /></td>
                                    </tr>) :
                                    transactionList.transactions?.length > 0 ?
                                        transactionList?.transactions?.map((item, index) => {
                                            const createdAtTimestamp = Date.parse(item.createdAt);
                                            const currentTimestamp = new Date().getTime();
                                            const timeDifference = currentTimestamp - createdAtTimestamp;
                                            const highlightClass = timeDifference < 60000 ? "latest_token" : "";
                                            const solAmount = (item.signature_type == "MANUAL BUY" || item.signature_type == "BUY") ? item.given_token : (item?.min_token_amount);
                                            const tokenAmount = ((item.signature_type == "MANUAL BUY") || item.signature_type == "BUY") ? (item?.min_token_amount) : (item.given_token);
                                            return (
                                                <>
                                                    {index == 1 && <div className="latest-tokens-logs-top"></div>}
                                                    <tr className={highlightClass}>
                                                        <td>{item?.token?.name ? item?.token?.name :"-"}</td>
                                                        <td>{maskAddress(item.token.address)}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestTransactions${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                            >
                                                                <i class="far fa-copy" onClick={() => copyTokenAddress(item.token.address, `latestTransactions${index}`)}
                                                                ></i>
                                                            </OverlayTrigger></td>
                                                        <td>{maskAddress(item.signature)}
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id={`tooltip-${index}`}>{copied[`signature${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                            >
                                                                <i class="far fa-copy" onClick={() => copyTokenAddress(item.signature, `signature${index}`)}
                                                                ></i>
                                                            </OverlayTrigger></td>
                                                        <td>{solAmount} SOL</td>
                                                        <td>{tokenAmount} {item.token.symbol}</td>
                                                        <td>{Number(Number(tokenAmount) / Number(solAmount)).toFixed(2)} {item.token.symbol}</td>
                                                        <td className={item?.transaction_status && item?.last_try ? "buy-action" : item?.last_try ? "error" : ""}>{item?.signature_type}</td>
                                                        <td>{convertUtcToLocal(item.action_start_at)}</td>
                                                        <td>{convertUtcToLocal(item.createdAt)}</td>
                                                    </tr>
                                                </>
                                            )
                                        }) :
                                        <>
                                            <tr>
                                                <td colSpan={9}>
                                                    <div className="no-transaction-area">
                                                        <img src={require("../assets/images/no-data.png")} alt="img" />
                                                        <p>No Transaction log available.</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>}
                        </tbody>
                    </Table>


                </div>


            </div>
        </Col>
    </>)
}
export default TransactionLog;